<template>
  <div class="d-flex align-items-center justify-content-between flex-wrap page-header">
    <button @click="$emit('showFilter')" class="position-relative text-primary cursor-pointer mb-0" :disabled="disabled">
      {{ $t("Filters") }}
      <img src="/media/buying/icons/arrow-right.svg" alt="">

      <slot name="filter-badges"></slot>
    </button>
<!--    <div class="search-field">-->
<!--      <label>-->
<!--        <img src="/media/buying/icons/search.svg" alt="">-->
<!--      </label>-->
<!--      <input type="text" :placeholder="searchInputText" @input="$emit('handleInput', $event)">-->
<!--    </div>-->
    <div class="w-auto mt-5 mt-md-0">
      <router-link :to="createButtonLink" v-if="createButtonLink && allowCreate">
        <button v-if="!showFilter" class="btn main-btn px-10" id="create-feedback">
          {{ createButtonText }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    showFilter: {
      type: Boolean,
      default: false
    },
    createButtonLink: {
      type: String,
      default: ''
    },
    createButtonText: {
      type: String,
      default: ''
    },
    searchInputText: {
      type: String,
      default: ''
    },
    allowCreate: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .search-field input {
    &:active, &:focus-visible {
      border-bottom: none;
    }
  }
  .page-header {
    background: #fff;
    padding-top: 29px;
    padding-left: 49px;
    padding-right: 50px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    > button {
      font-size: 1.25rem;
      background: transparent;
      border: none;
      font-weight: 600;
      padding: 0;
      line-height: 1.2;
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
    @media (max-width: 1200px) {
      position: revert !important;
    }
    @media (max-width: 992px) {
      padding-left: 36px;
      padding-right: 15px;
      padding-top: 20px;
    }
    @media (max-width: 768px) {

    }
  }
  body:not(.currentStep--step-7) {
    .page-header {
      position: sticky;
      top: 119px;
      z-index: 9;
    }
  }
</style>
