<template>
  <div class="ri-empty-state">
    <img :src="iconPath" alt="iconPath">
    <h4>{{ title }}</h4>
    <p>{{ text }}</p>

    <button v-if="buttonText && showButton" @click="actionButtonClick">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "RequestIdeaEmptyState",
  emits: ['handleClickButton'],
  props: {
    iconPath: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    actionButtonClick() {
      this.$emit('handleClickButton');
    }
  }
}
</script>

<style scoped lang="scss">
.ri-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 56px;
    margin-bottom: 28px;
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #D4D4D4;
    margin-bottom: 8px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #D4D4D4;
    margin-bottom: 0;
    white-space: pre-line;
  }
  button {
    margin-top: 25px;
    background: rgba(67, 91, 244, 0.06);
    border-radius: 57px;
    padding: 14px;
    border: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: rgba(67, 91, 244, 0.8);
    min-width: 246px;
    transition: .3s ease-in-out;
    &:hover {
      background: rgba(67, 91, 244, 0.10);
    }
  }

  @media (max-width: 768px) {
    img {
      width: 40px;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 18px;
      line-height: 140%;
    }
    p {
      font-size: 14px;
      line-height: 140%;
    }
  }
}
</style>
